import { Button, Divider, Modal } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { Step } from "../components/step";
import { convertBase64ToBlob } from "../helpers";
import { FINISH, INFORMATION } from "../routes/path";
import {
  getDisclamerForm,
  signDisclamerOfRunner,
  uploadImageToCloud,
} from "../services";
import {
  CheckOutlined,
  EditOutlined,
  LeftOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

const EwaiverPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key")?.replaceAll(" ", "+");
  const resign = searchParams.get("resign");
  const navigate = useNavigate();
  const [runnerInfo, setRunnerInfo] = useState();
  const signatureRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [signature, setSignature] = useState(null);
  const [openModalSignature, setOpenModalSignature] = useState(false);

  const handleConvertDisclamerForm = (disclamerForm) => {
    const date = moment().format("DD");
    const month = moment().format("MM");
    const year = moment().format("YYYY");

    let disclamerFormFormated = disclamerForm
      ?.replaceAll("{T&ecirc;n giải chạy}", runnerInfo?.marathonName)
      ?.replaceAll("{Họ v&agrave; t&ecirc;n}", runnerInfo?.runnerOrderName)
      ?.replaceAll("{Ng&agrave;y sinh}", runnerInfo?.runnerOrderBirthday)
      ?.replaceAll(
        "{Giới t&iacute;nh}",
        runnerInfo?.runnerOrderGender === "MALE" ? "Nam" : "Nữ"
      )
      ?.replaceAll("{Số điện thoại}", runnerInfo?.runnerOrderPhone)
      ?.replaceAll("{Email}", runnerInfo?.runnerOrderEmail)
      ?.replaceAll("{Địa chỉ}", runnerInfo?.runnerOrderAddress)
      ?.replaceAll("{date}", date)
      ?.replaceAll("{month}", month)
      ?.replaceAll("{year}", year)
      ?.replaceAll("{Số CCCD}", runnerInfo?.runnerOrderPassport || "")
      ?.replaceAll("{Cự ly}", runnerInfo?.runningDistanceName)
      ?.replaceAll(
        "{Thời gian diễn ra}",
        moment(runnerInfo?.marathonStartTime).format("DD/MM/YYYY")
      )
      ?.replaceAll("{Team}", runnerInfo?.runnerOrderTeamName || "")
      ?.replaceAll(
        "{Quốc tịch}",
        runnerInfo?.runner_order_detail_nationality || ""
      )
      ?.replaceAll("{Địa điểm tổ chức}", runnerInfo?.marathon_address || "")
      ?.replaceAll("{C&acirc;u lạc bộ}", runnerInfo?.runnerOrderClubName || "")
      ?.replaceAll(
        "{T&ecirc;n người li&ecirc;n hệ khẩn cấp}",
        runnerInfo?.runnerOrderEmergencyFullName
      )
      ?.replaceAll(
        "{SĐT người li&ecirc;n hệ khẩn cấp}",
        runnerInfo?.runnerOrderEmergencyPhone
      )
      ?.replaceAll(
        "{Chữ k&yacute;}",
        signature
          ? `<img src=${signature} alt="signature" style="height: 8rem; margin: auto" id="signature-of-runner"/>`
          : "{Chữ k&yacute;}"
      );

    return disclamerFormFormated;
  };

  const clearSignature = () => {
    if (signatureRef) {
      signatureRef.current.clear();
    }
  };

  const getSignature = async () => {
    const isEmpty = signatureRef.current.isEmpty();
    if (isEmpty) {
      alert(t("please_sign_name"));
    } else {
      const canvas = signatureRef.current.getCanvas();
      const base64Image = canvas.toDataURL();

      setOpenModalSignature(false);
      setSignature(base64Image);
      signatureRef.current.clear();
    }
  };

  const renderModalSignature = () => {
    return (
      <Modal
        title={t("confirm_sign_name")}
        centered
        open={openModalSignature}
        onCancel={() => {
          setOpenModalSignature(false);
          clearSignature();
        }}
        width={1000}
        footer={null}
      >
        <small>
          Vui lòng <strong>KÝ TÊN</strong> của bạn bên dưới và bấm xác nhận /
          Please <strong>SIGN</strong> your signature below and click confirm
        </small>
        <SignatureCanvas
          penColor="black"
          canvasProps={{ height: 400, width: 1000, className: "sigCanvas" }}
          velocityFilterWeight={1}
          dotSize={0.1}
          ref={signatureRef}
        />
        <div className="flex justify-center gap-1">
          <Button
            type="default"
            className="login-form-button"
            onClick={clearSignature}
            icon={<ReloadOutlined />}
            iconPosition="start"
          >
            {t("re_sign")}
          </Button>
          <Button
            type="primary"
            className="login-form-button text-[#D1F700]"
            onClick={getSignature}
            icon={<CheckOutlined />}
            iconPosition="start"
          >
            {t("confirm")}
          </Button>
        </div>
      </Modal>
    );
  };

  const _signDisclamerOfRunner = async () => {
    setLoading(true);
    try {
      const blob = convertBase64ToBlob(signature);
      const formData = new FormData();

      formData.append("file", blob);
      formData.append(
        "file_name",
        `signatures/${key}.png`
      );

      const res = await uploadImageToCloud(formData);

      var signatureDiv = document.getElementById("signature-of-runner");
      signatureDiv.src = res;

      var disclamerDiv = document.getElementById(
        "disclamer-of-runner"
      ).firstChild;

      var serializer = new XMLSerializer();
      var stringRepresentation = serializer.serializeToString(disclamerDiv);

      await signDisclamerOfRunner({
        key,
        linkFile: stringRepresentation,
      });

      navigate(`/${FINISH}?key=${key}`);
    } catch (error) {
      alert(t("error_sign_disclaimer"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const _getDisclamerForm = async () => {
      const res = await getDisclamerForm({
        key,
      });

      setRunnerInfo(res?.[0]);
    };

    if (key) {
      _getDisclamerForm();
    }
  }, [key]);

  useEffect(() => {
    if (runnerInfo) {
      if (runnerInfo?.runnerOrderDetailEwaiver && !resign) {
        navigate(`/${FINISH}?key=${key}`);
      }
    }
  }, [runnerInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {runnerInfo && (
        <Step>
          {renderModalSignature()}
          <div className="shadow-none md:shadow-2xl p-0 md:p-5 relative">
            <div className="text-center">
              <div className="font-bold text-[14px] md:text-2xl">
                {t("sign_disclaimer_header")}
              </div>
              <div className="text-xs md:text-sm text-[red] mb-3">
                {t("header_warning")}
              </div>
            </div>
            <Divider orientation="left" className="my-2"></Divider>

            <div className="bg-[#ffffff] h-[100vh] overflow-auto">
              <div className="bg-white rounded">
                <div id="disclamer-of-runner">
                  <div
                    className="p-0 md:p-2"
                    dangerouslySetInnerHTML={{
                      __html: handleConvertDisclamerForm(
                        runnerInfo?.eWaiverReportSample
                      ),
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-2 text-center sticky bottom-0 bg-white">
              {!signature && (
                <div className="p-3 flex justify-center gap-2">
                  <Button
                    type="primary"
                    className="login-form-button"
                    onClick={() =>
                      navigate(
                        resign
                          ? `/${INFORMATION}?key=${key}&resign=true`
                          : `/${INFORMATION}?key=${key}`
                      )
                    }
                    icon={<LeftOutlined />}
                    iconPosition="start"
                  >
                    {t("back")}
                  </Button>
                  <Button
                    type="primary"
                    className="login-form-button w-[150px] text-[#D1F700]"
                    onClick={() => setOpenModalSignature(true)}
                    icon={<EditOutlined />}
                    iconPosition="start"
                  >
                    {t("sign")}
                  </Button>
                </div>
              )}

              {signature && (
                <div className="p-3 flex flex-col-reverse md:flex-row md:justify-center md:gap-3">
                  <Button
                    type="primary"
                    className="login-form-button mb-2"
                    onClick={() =>
                      navigate(
                        resign
                          ? `/${INFORMATION}?key=${key}&resign=true`
                          : `/${INFORMATION}?key=${key}`
                      )
                    }
                    icon={<LeftOutlined />}
                    iconPosition="start"
                  >
                    {t("back")}
                  </Button>

                  <Button
                    type="primary"
                    className="login-form-button mb-2"
                    onClick={() => setOpenModalSignature(true)}
                    icon={<ReloadOutlined />}
                    iconPosition="start"
                  >
                    {t("re_sign")}
                  </Button>

                  <Button
                    type="primary"
                    className="login-form-button mb-2 text-[#D1F700]"
                    onClick={_signDisclamerOfRunner}
                    loading={loading}
                    icon={<CheckOutlined />}
                    iconPosition="start"
                  >
                    {t("send_disclaimer")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Step>
      )}
    </div>
  );
};

export default EwaiverPage;
