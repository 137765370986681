import { Button, Checkbox, Divider, Form, Input, Radio } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Step } from "../components/step";
import { GENDER, RECEIVE_TYPE } from "../constants";
import { EWAIVER, FINISH } from "../routes/path";
import { getDisclamerForm, updatePresentative } from "../services";
import { useTranslation } from "react-i18next";
import { RightOutlined } from "@ant-design/icons";

const InformationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [runnerInfo, setRunnerInfo] = useState();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key")?.replaceAll(" ", "+");
  const resign = searchParams.get("resign");
  const [receiveType, setReceiveType] = useState(RECEIVE_TYPE.LIVE);
  const [loading, setLoading] = useState(false);
  const [agreeRegulation, setAgreeRegulation] = useState(false);

  const onFinish = async (data) => {
    if (!agreeRegulation) {
      alert(t("agree_regulations"));
    } else {
      setLoading(true);
      try {
        await updatePresentative({
          ...data,
          key,
          runnerOrderRepresentativeType:
            receiveType === RECEIVE_TYPE.INSTANCE ? "OTHER" : "YOURSELF",
        });
        navigate(
          resign
            ? `/${EWAIVER}?key=${key}&resign=true`
            : `/${EWAIVER}?key=${key}`
        );
      } catch (error) {
        alert(t("an_error_occurred"));
      } finally {
        setLoading(false);
      }
    }
  };

  const onChangeReceiveType = (e) => {
    setReceiveType(e.target.value);
  };

  const renderRunnerInfoItem = (label, value) => {
    return (
      <div className="mb-3">
        <div className="text-[gray]">{label}</div>
        <div className="font-bold">{value}</div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="bg-[#E0E6ED] p-2 md:p-4 rounded">
        <div className="font-semibold mb-1">
          <span className="text-[red]">*</span>{" "}
          {t("full_name_of_authorized_person")}
        </div>
        <Form.Item
          name="runnerOrderRepresentativeFullName"
          rules={[
            {
              required: true,
              message: t("enter_full_infor"),
            },
          ]}
          className="mb-3"
          initialValue={runnerInfo?.runnerOrderRepresentativeFullName}
        >
          <Input
            placeholder={t("enter_information")}
            size="large"
            defaultValue={runnerInfo?.runnerOrderRepresentativeFullName}
          />
        </Form.Item>

        <div className="font-semibold mb-1">
          <span className="text-[red]">*</span>{" "}
          {t("email_of_authorized_person")}
        </div>
        <Form.Item
          name="runnerOrderRepresentativeEmail"
          rules={[
            {
              required: true,
              message: t("enter_full_infor"),
            },
          ]}
          className="mb-3"
          initialValue={runnerInfo?.runnerOrderRepresentativeEmail}
        >
          <Input
            placeholder={t("enter_information")}
            size="large"
            defaultValue={runnerInfo?.runnerOrderRepresentativeEmail}
          />
        </Form.Item>

        <div className="font-semibold mb-1">
          <span className="text-[red]">*</span>{" "}
          {t("passport_of_authorized_person")}
        </div>
        <Form.Item
          name="runnerOrderRepresentativeCCCD"
          rules={[
            {
              required: true,
              message: t("enter_full_infor"),
            },
          ]}
          className="mb-3"
          initialValue={runnerInfo?.runnerOrderRepresentativeCCCD}
        >
          <Input
            placeholder={t("enter_information")}
            size="large"
            defaultValue={runnerInfo?.runnerOrderRepresentativeCCCD}
          />
        </Form.Item>

        <div className="font-semibold mb-1">
          <span className="text-[red]">*</span>{" "}
          {t("phone_of_authorized_person")}
        </div>
        <Form.Item
          name="runnerOrderRepresentativePhone"
          rules={[
            {
              required: true,
              message: t("enter_full_infor"),
            },
          ]}
          className="mb-3"
          initialValue={runnerInfo?.runnerOrderRepresentativePhone}
        >
          <Input
            placeholder={t("enter_information")}
            size="large"
            defaultValue={runnerInfo?.runnerOrderRepresentativePhone}
          />
        </Form.Item>
      </div>
    );
  };

  useEffect(() => {
    const _getDisclamerForm = async () => {
      const res = await getDisclamerForm({
        key,
      });

      setRunnerInfo(res?.[0]);

      setReceiveType(
        res?.[0]?.runnerOrderRepresentativeType === "OTHER"
          ? RECEIVE_TYPE.INSTANCE
          : RECEIVE_TYPE.LIVE
      );
    };

    if (key) {
      _getDisclamerForm();
    }
  }, [key]);

  useEffect(() => {
    if (runnerInfo) {
      if (runnerInfo?.runnerOrderDetailEwaiver && !resign) {
        navigate(`/${FINISH}?key=${key}`);
      }
    }
  }, [runnerInfo]);

  useEffect(() => {
    if (resign) {
      setAgreeRegulation(true);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {runnerInfo && (
        <Step>
          <div className="shadow-none md:shadow-2xl p-0 md:p-5">
            <div className="text-center">
              <div className="font-bold text-[14px] md:text-2xl">
                {t("runner_info")}
              </div>
              <div className="text-xs md:text-sm text-[red] mb-3">
                {t("header_warning")}
              </div>
            </div>
            <Divider orientation="left" className="my-2"></Divider>

            <div className="block sm:grid sm:grid-cols-2">
              <div>
                <div>
                  {renderRunnerInfoItem(
                    t("name"),
                    runnerInfo?.runnerOrderName || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("distance"),
                    runnerInfo?.runningDistanceName || t("none")
                  )}
                  {renderRunnerInfoItem(
                    "Bib",
                    runnerInfo?.runnerOrderDetailBib || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("name_of_bib"),
                    runnerInfo?.runnerOrderDetailBibName || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("dob"),
                    runnerInfo?.runnerOrderBirthday || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("gender"),
                    GENDER[runnerInfo?.runnerOrderGender] || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("passport"),
                    runnerInfo?.runnerOrderPassport || t("none")
                  )}

                  {/* {renderRunnerInfoItem(
                    t("phone"),
                    runnerInfo?.runnerOrderPhone
                  )}
                  {renderRunnerInfoItem(
                    t("passport"),
                    runnerInfo?.runnerOrderPassport
                  )}
                  {renderRunnerInfoItem("Email", runnerInfo?.runnerOrderEmail)}
                  {renderRunnerInfoItem(
                    t("address"),
                    runnerInfo?.runnerOrderAddress
                  )}
                  {renderRunnerInfoItem(
                    t("dob"),
                    runnerInfo?.runnerOrderBirthday
                  )} */}
                </div>
              </div>
              <div>
                <div>
                  {renderRunnerInfoItem(
                    t("phone"),
                    runnerInfo?.runnerOrderPhone || t("none")
                  )}
                  {renderRunnerInfoItem(
                    "Email",
                    runnerInfo?.runnerOrderEmail || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("address"),
                    runnerInfo?.runnerOrderAddress || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("club"),
                    runnerInfo?.runnerOrderClubName || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("size_of_racekit"),
                    runnerInfo?.runnerOrderDetailShirtSize || t("none")
                  )}

                  {/* {renderRunnerInfoItem(
                    t("marathon_name"),
                    runnerInfo?.marathonName
                  )}
                  {renderRunnerInfoItem(
                    t("distance"),
                    runnerInfo?.runningDistanceName
                  )}
                  {renderRunnerInfoItem(
                    t("team"),
                    runnerInfo?.runnerOrderTeamName || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("club"),
                    runnerInfo?.runnerOrderClubName || t("none")
                  )} */}
                  {renderRunnerInfoItem(
                    t("emergency_fullname"),
                    runnerInfo?.runnerOrderEmergencyFullName || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("emergency_phone"),
                    runnerInfo?.runnerOrderEmergencyPhone || t("none")
                  )}
                  {/* {renderRunnerInfoItem(t("infor_expand_of_runner"), "")} */}
                </div>
              </div>
            </div>

            <Divider orientation="left" style={{ margin: 0 }}></Divider>
            <div className="mt-4">
              <Radio.Group
                onChange={onChangeReceiveType}
                value={receiveType}
                size="large"
              >
                <div className="mb-2">
                  <Radio value={RECEIVE_TYPE.LIVE} className="font-semibold">
                    {t("bib_recei_yourseft")}
                  </Radio>
                </div>
                <div>
                  <Radio
                    value={RECEIVE_TYPE.INSTANCE}
                    className="font-semibold"
                  >
                    {t("bib_recei_orther")}
                  </Radio>
                </div>
              </Radio.Group>
              {receiveType === RECEIVE_TYPE.INSTANCE && (
                <div className="text-[red]">{t("bib_recei_warning")}</div>
              )}

              <Form
                name="normal_login"
                className="login-form"
                initialValues={{}}
                onFinish={onFinish}
              >
                <div className="mt-2">
                  {receiveType === RECEIVE_TYPE.INSTANCE && renderForm()}
                </div>

                <Divider orientation="left"></Divider>

                <Checkbox
                  checked={agreeRegulation}
                  onChange={(value) => {
                    setAgreeRegulation(value.target.checked);
                  }}
                >
                  {t("is_read")}{" "}
                  <a
                    // href={`/regulations?key=${key}`}
                    href={`https://docs.google.com/document/d/1TEuIRMX1z7xCSJzE4EiKpmnDxp0dLorpolGZ0I5Uqwg/edit`}
                    className="text-[blue]"
                    target="_blank"
                  >
                    {`${t("regulations")}`}
                  </a>
                </Checkbox>

                <div className="mt-4 text-center">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button w-[150px] text-[#D1F700]"
                      loading={loading}
                      icon={<RightOutlined />}
                      iconPosition="end"
                    >
                      {t("next")}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </Step>
      )}
    </div>
  );
};

export default InformationPage;
